// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* material-icons.scss */
@import 'material-icons/iconfont/material-icons.scss';

//Font family added
@import './assets/fonts/_fonts.scss';


$primary_font: 'Inter-Regular';
$font_light: 'Inter-Light';
$font_extra_light: 'Inter-ExtraLight';
$font_bold: 'Inter-Bold';
$font_thin: 'Inter-Thin';

$custom-typography: mat.define-typography-config($font-family: 'Inter-Regular',
  );

@include mat.core();
$dark-primary: #1C202B; // #1B1F29; // #2A2E3A;
$light-primary: white;
$dark-text-primary: #5a6dd6;
$dark-text-color: #B8ACC7;
$dark-tools-icon-color: #B8ACC7;
// Background palette for dark themes.
$custom-blue-palette: (
  50: #ebedfa,
  100: #ced3f3,
  200: #adb6eb,
  300: #8c99e2,
  400: #7383dc,
  500: #5a6dd6,
  600: #5265d1,
  700: #485acc,
  800: #3f50c6,
  900: #2e3ebc,
  A100: #ffffff,
  A200: #ccd1ff,
  A400: #99a3ff,
  A700: #808cff,
  contrast: (50: $dark-primary,
    100: $dark-primary,
    200: $dark-primary,
    300: $dark-primary,
    400: $dark-primary,
    500: $light-primary,
    600: $light-primary,
    700: $light-primary,
    800: $light-primary,
    900: $light-primary,
    A100: $dark-primary,
    A200: $dark-primary,
    A400: $dark-primary,
    A700: $light-primary,
  )
);
$IM-primary: mat.define-palette($custom-blue-palette);
$IM-accent: mat.define-palette(mat.$gray-palette, A200, A100, A400);
$IM-warn: mat.define-palette(mat.$red-palette);


$IM-light-theme: mat.define-light-theme((color: (primary: $IM-primary, accent: $IM-accent, warn: $IM-warn),  // Only include `typography` and `density` in the default dark theme.
typography: $custom-typography));

$IM-dark-theme: mat.define-dark-theme((color: (primary: $IM-primary, accent: $IM-accent, warn: $IM-warn),  // Only include `typography` and `density` in the default dark theme.
typography: $custom-typography));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($IM-light-theme);

.darkMode {
  @include mat.all-component-colors($IM-dark-theme);
}
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  padding: 0;
  font-family: $primary_font; //Roboto, "Helvetica Neue", sans-serif;
  margin: 0px;
  ;
}

.custom-textarea{
  .mat-mdc-text-field-wrapper {
      height: auto !important;
  }
}
.custom-input{
  .mat-mdc-text-field-wrapper {
      height: 36px !important;
      border-radius: 6px !important;
  }
  .mdc-text-field{
    padding: 0px;
  }
  .mat-mdc-form-field {
    input.mat-mdc-input-element{
      font-size: 12px !important;
    }
    padding-bottom: 12px;
      .mat-mdc-icon-button.mat-mdc-button-base {
        width: 38px !important;
        height: 36px !important;
        padding: 6px 5px !important;
      }
  
      .mat-mdc-form-field-subscript-wrapper {
          height: 0px !important;
      }
  
      .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
          padding-top: 4px !important;
          padding-bottom: 4px !important;
      }
  
      .mat-mdc-form-field-infix {
          min-height: 36px !important;
      }
      .mdc-line-ripple::after,.mdc-line-ripple::before {
        border-bottom-width: 0px;
    }
  }
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px !important;
}
// }
.custom-select{
.mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 11px;
  padding-bottom: 5px;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
}

//font-family
.fontFmly-light {
  font-family: $font_light !important;
}

.fontFmly-bold {
  font-family: $font_bold !important;
}

.fontFmly-extra-light {
  font-family: $font_extra_light !important;
}

.fontFmly-thin {
  font-family: $font_thin !important;
}

/***
* Custom material style added globally
*/
.mat-form-field-flex, .mat-mdc-form-field-flex {
  padding: 0px 0px 0px 2px !important;
  border-radius: 6px !important;
}

.mat-expansion-panel-header {
  padding: 0 0px 0px 10px !important
}

.mat-expansion-panel-header,
.mat-expansion-panel-header.mat-expanded {
  height: 35px;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  border-top: 0px !important;
  position: relative;
  top: -2px;
}

.mat-form-field-underline {
  width: 0% !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper, .mat-mdc-form-field-type-mat-input .mat-form-field-wrapper {
  padding-bottom: 10px;
}

.mat-form-field-flex, .mat-mdc-form-field-flex {
  border-radius: 5px;
}

.mat-expansion-panel-header.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
  margin: 6px 8px 0 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  font-size: 12px;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 24px;
}

.mat-expansion-panel-spacing {
  margin: 0px !important;
}

.mat-expansion-panel-body {
  padding: 0 1px 0px 8px !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 0px !important;
}

.mat-tab-label {
  height: 38px !important;
}

.mat-tab-label-active .mat-tab-label-content {
  // font-weight: 600;
}

.darkMode .mat-tab-label,
.darkMode .mat-tab-link {
  color: $dark-text-color !important;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0px !important;
}

.mat-tab-body-content {
  height: 100%;
  overflow: hidden !important;
}

.mat-menu-panel {
  min-height: 40px !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-mini-fab .mat-button-wrapper {
  padding: 0px !important;
}

.darkMode .mat-flat-button.mat-accent,
.darkMode .mat-raised-button.mat-accent,
.darkMode .mat-fab.mat-accent,
.darkMode .mat-mini-fab.mat-accent {
  color: $dark-text-color;
}

.viewmenu_panel {
  margin-right: 40px !important;
  margin-bottom: -40px !important;
  background: none !important;
  top: -44px !important;
  left: 44px !important;
  position: relative !important;
  min-height: 40px !important;
}

// Dark Mode
.darkMode.mat-app-background {
  background-color: #1C202B;
}

.darkMode {
  .mat-app-background {
    background-color: #1C202B;
  }

  .mat-header-background {
    background-color: #212532;
  }

  .mat-select-background {
    background-color: #343741 !important;
  }

  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary {
    color: #fff;
  }

  .hover:hover {
    background: rgba(255, 255, 255, 0.04) !important;
  }

  .cardHover:hover {
    background: rgb(102 102 102 / 32%) !important;
  }

  .cardActive {
    background: rgb(108 108 108 / 50%) !important;
  }

  .mat-app-transparent-background {
    background-color: rgb(44 48 58 / 50%) !important;
    color: $dark-text-color;
  }

  .input-bg {
    background-color: #3F424B !important;
    color: $dark-text-color;
  }

  .mat-card-background {
    background-color: #2B2F3A !important;
    color: $dark-text-color !important;
  }

  .mat-img-bg {
    background-color: #4c4c64;
  }

  .mat-card-list-background {
    background-color: #2e3040 !important;
    color: #b4a5c7 !important;
  }

  .mat-expansion-panel {
    // background-color: #2B2F3A !important;
    // color: $dark-text-color !important;
    background-color: rgba(44, 48, 58, 0) !important;
    color: $dark-text-color;
  }
  .mat-app-none-background{
    background-color: rgba(255, 255, 255, 0) !important;
    color: $dark-text-color;
  }

  .mat-expansion-panel-header-title {
    color: $dark-text-color !important;
  }

  .mat-list-base .mat-list-item {
    color: #dbdbdb //$dark-text-color;
  }

  .mat-form-field {
    input {
      font-size: 12px !important;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: -2px;
    }
  }

  .mat-form-field-flex, .mat-mdc-form-field-flex {
    background-color: #3F424B;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #3F424B !important;
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #3F424B !important;
  }


  .mat-form-field-prefix {
    color: #A1A3A8 !important;
  }

  .mat-input-element {
    caret-color: #A1A3A8 !important;
  }



  .mat-form-field.mat-focused .mat-form-field-label {
    color: #A1A3A8 !important;
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .darkMode .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #03a9f400;
  }

  .mat-tab-labels {
    background-color: #222431;

    .mat-tab-label {
      border-right: 1px solid rgba(255, 255, 255, 0.08);
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      background-color: #03a9f400 !important;
    }

    .mat-tab-label-active .mat-tab-label-content {
      color: $dark-text-primary;
    }
  }

  .mat-elevation-z4 {
    box-shadow: none;
  }
}

//Display
.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-contents {
  display: contents !important;
}

.display-flexbox {
  display: flexbox !important;
}

.display-flow-root {
  display: flow-root !important;
}

.display-flex {
  display: flex !important;
}

.display-inline-flex {
  display: inline-flex !important;
}

.display-initial {
  display: initial !important;
}

.display-none {
  display: none !important;
}

.display-table {
  display: table !important;
}


.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

// Alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

// Text Size
.head1-text {
  font-size: 30px;
}

.head2-text {
  font-size: 20px;
}

.head3-text {
  font-size: 18px;
}

.head4-text {
  font-size: 16px;
}

.head5-text {
  font-size: 14px;
}

.paragraph-text {
  font-size: 12px;
}

.description-text {
  font-size: 10px;
}

.timeline-text {
  font-size: 8px;
}

//Text Weight
.text-weight-bold {
  font-weight: bold !important;
}

.text-weight-bolder {
  font-weight: bolder !important;
}

.text-weight-normal {
  font-weight: 400 !important;
}

.text-weight-light {
  font-weight: 300 !important;
}

.text-weight-500 {
  font-weight: 500 !important;
}

.text-weight-600 {
  font-weight: 600 !important;
}

.text-weight-700 {
  font-weight: 700 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

//Text style
.text-italic {
  font-style: italic !important;
}

//Paragraph Alignment
.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

//Text Transformation
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// Text Color
.text-color-orange {
  color: #FFB800 !important;
}

.text-color-violet {
  color: #9747ff !important;
}

.text-color-blue {
  color: #0066FF !important;
}

.text-color-lightblue,
.active {
  color: $dark-text-primary !important;
}

.rightSection {
  .hoverEffect:hover {
    border-color: #5a6dd6b2 !important;
  }
}

.border-none-color {
  border-color: #00000000 !important;
}

.border-active-color {
  border-color: $dark-text-primary !important;
}

.text-color-grey {
  color: #ffffff80 !important;
}

.text-color-lightgrey {
  color: #768494 !important;
}

.text-color-green {
  color: #1AB86C !important;
}

.text-color-white {
  color: #fff !important;
}

.custom-bg-accent {
  background-color: #41454F;
  color: $dark-text-color;
}

.text-color-moonRaker {
  color: #C9BFD4 !important;
}

.custom-text-color {
  color: $dark-text-color !important;
}

// Equal width
$width-range :150,
200,
250,
300,
350,
400,
450,
500,
550,
600,
650,
700,
750,
800,
850,
900,
950,
1000;

@each $width in $width-range {
  .equal-width-#{$width} {
    width: $width + px !important;
    height: $width + px !important;
  }

  .width-#{$width} {
    width: $width + px !important;
  }

  .min-width-#{$width} {
    min-width: $width + px !important;
  }

  .height-px-#{$width} {
    height: $width + px !important;
  }

  .height-vh-#{$width} {
    height: $width + vh !important;
  }
}

.min-width-inherit {
  min-width: inherit !important;
}

//Spacing
$start-space-range: 0;
$end-space-range: 200;


@for $i from $start-space-range through $end-space-range {

  // width section
  // width pixel
  .width-#{$i} {
    width: $i + px !important;
  }

  // minimum width pixel
  .min-width-#{$i} {
    min-width: $i + px !important;
  }

  .container-#{$i} {
    width: #{$i}#{"%"} !important;
    margin: 0 auto;
  }

  //height section
  // height pixel
  .height-px-#{$i} {
    height: $i + px !important;
  }

  // height viewport-percentage
  .height-vh-#{$i} {
    height: $i + vh !important;
  }

  // line-height
  .line-height-#{$i} {
    line-height: $i + px !important;
  }

  // width and hight equal size
  .equal-width-#{$i} {
    width: $i + px !important;
    height: $i + px !important;
  }

  // Margin section
  // Margin pixel
  // Margin Left, Right, Top, Bottom 
  .margin-#{$i} {
    margin: $i + px !important;
  }

  // Margin left
  .margin-left-#{$i} {
    margin-left: $i + px !important;
  }

  // Margin right
  .margin-right-#{$i} {
    margin-right: $i + px !important;
  }

  // Margin top
  .margin-top-#{$i} {
    margin-top: $i + px !important;
  }

  // Margin bottom
  .margin-bottom-#{$i} {
    margin-bottom: $i + px !important;
  }



  // Margin percentage
  // Margin Left, Right, Top, Bottom 
  .margin-pct-#{$i} {
    margin: #{$i}#{"%"} !important;
  }

  // Margin left
  .margin-left-pct-#{$i} {
    margin-left: #{$i}#{"%"} !important;
  }

  // Margin right
  .margin-right-pct-#{$i} {
    margin-right: #{$i}#{"%"} !important;
  }

  // Margin top
  .margin-top-pct-#{$i} {
    margin-top: #{$i}#{"%"} !important;
  }

  // Margin bottom
  .margin-bottom-pct-#{$i} {
    margin-bottom: #{$i}#{"%"} !important;
  }

  // Padding Section
  // Padding Pixel
  // Padding Left, Right, Top, Bottom 
  .padding-#{$i} {
    padding: $i + px !important;
  }

  // Padding left
  .padding-left-#{$i} {
    padding-left: $i + px !important;
  }

  // Padding right
  .padding-right-#{$i} {
    padding-right: $i + px !important;
  }

  // Padding top
  .padding-top-#{$i} {
    padding-top: $i + px !important;
  }

  // Padding bottom
  .padding-bottom-#{$i} {
    padding-bottom: $i + px !important;
  }




  // Padding Section
  // Padding Percentage
  // Padding Left, Right, Top, Bottom 
  .padding-pct-#{$i} {
    padding: #{$i}#{"%"} !important;
  }

  // Padding left
  .padding-left-pct-#{$i} {
    padding-left: #{$i}#{"%"} !important;
  }

  // Padding right
  .padding-right-pct-#{$i} {
    padding-right: #{$i}#{"%"} !important;
  }

  // Padding top
  .padding-top-pct-#{$i} {
    padding-top: #{$i}#{"%"} !important;
  }

  // Padding bottom
  .padding-bottom-pct-#{$i} {
    padding-bottom: #{$i}#{"%"} !important;
  }

  // Border-radius
  // Border-radius Left, Right, Top, Bottom 
  .rounded-#{$i} {
    border-radius: $i + px !important;
  }

  // Border width Left, Right, Top, Bottom
  .border-#{$i} {
    border-width: $i + px !important;
  }

  // Border width Left
  .border-left-#{$i} {
    border-left-width: $i + px !important;
  }

  // Border width Top
  .border-top-#{$i} {
    border-top-width: $i + px !important;
  }

  // Border width Right
  .border-right-#{$i} {
    border-right-width: $i + px !important;
  }

  // Border width Bottom
  .border-bottom-#{$i} {
    border-bottom-width: $i + px !important;
  }

  // Mat icon Size
  .mat-icon-#{$i} {
    width: $i + px !important;
    height: $i + px !important;
    line-height: $i + px !important;
    font-size: $i + px !important;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

$border-radius-0: 0px;

.rounded-top-0 {
  border-top-left-radius: $border-radius-0 !important;
  border-top-right-radius: $border-radius-0 !important;
}

.rounded-right-0 {
  border-top-right-radius: $border-radius-0 !important;
  border-bottom-right-radius: $border-radius-0 !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: $border-radius-0 !important;
  border-bottom-left-radius: $border-radius-0 !important;
}

.rounded-left-0 {
  border-top-left-radius: $border-radius-0 !important;
  border-bottom-left-radius: $border-radius-0 !important;
}



// Border 
// Border Style
.border-solid {
  border-style: solid !important;
}

.border-top-solid {
  border-top-style: solid !important;
}

.border-right-solid {
  border-right-style: solid !important;
}

.border-bottom-solid {
  border-bottom-style: solid !important;
}

.border-left-solid {
  border-left-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-top-dashed {
  border-top-style: dashed !important;
}

.border-right-dashed {
  border-right-style: dashed !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

.border-left-dashed {
  border-left-style: dashed !important;
}

.border-space:before {
  content: "" !important;
  position: absolute !important;
  border: 10px dashed !important;
  top: -8px !important;
  bottom: -8px !important;
  left: -8px !important;
  right: -8px !important;
}

.border-bg-space {
  background-color: transparent !important;
  background-position: 0 0, 0 0, 100% 0, 0 100% !important;
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px !important;
  background-repeat: no-repeat !important;
  background-image: repeating-linear-gradient(0deg, #5B5E65, #5B5E65 13px, transparent 17px, transparent 25px), repeating-linear-gradient(90deg, #5B5E65, #5B5E65 13px, transparent 17px, transparent 25px), repeating-linear-gradient(180deg, #5B5E65, #5B5E65 13px, transparent 17px, transparent 25px), repeating-linear-gradient(270deg, #5B5E65, #5B5E65 13px, transparent 17px, transparent 25px) !important;
}



//position
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

// float
.float-right {
  float: right;
}

.float-left {
  float: left;
}

//Cursor
.pointer {
  cursor: pointer;
}


// Scrollbar 
*::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

*::-webkit-scrollbar {
  background-color: #777777;
}

*::-webkit-scrollbar-thumb {
  background: #333333;
}

* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.text-placeholder {
  color: #A1A3A8 !important;
}

.reverse-theme {
  background-color: #fff !important;
  color: #000 !important;
}

.opacity-1 {
  opacity: 1;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

// custom color picker start
.darkMode {
  .color-picker {
    border: 0px !important;
    background-color: rgb(23 27 37) !important;
    color: $dark-text-color !important;
    border-radius: 5px;

    .preset-area .preset-label,
    .hex-text .box div,
    .cmyk-text .box div,
    .hsla-text .box div,
    .rgba-text .box div,
    .value-text .box div {
      color: $dark-text-color !important;
    }

    .hex-text .box input,
    .cmyk-text .box input,
    .hsla-text .box input,
    .rgba-text .box input,
    .value-text .box input {
      flex: 1 1 auto;
      padding: 1px;
      border: 0px !important;
      border-radius: 5px;
      background-color: rgb(64 64 64);
      color: $dark-text-color !important;

      &:focus-visible {
        outline: none !important;
      }

      .preset-area hr {
        border: 0.1px solid #ffffff2e !important;
      }
    }
  }

  .list-item-text-color {
    color: #dbdbdb !important;
  }

  button.btn.btn-custom-ok {
    padding: 7px 14px;
    background: #0a78ff;
    color: #fff;
    font-size: 12px;
    border: 0px;
    border-radius: 3px;
  }

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
}

.pointer-none {
  pointer-events: none;
}

.pointer-auto {
  pointer-events: auto;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

.customSVG {
  svg {
    fill: $dark-tools-icon-color !important;

    path {
      stroke: $dark-tools-icon-color !important;
    }
  }
}

.tools-icon-color {
  .mat-icon {
    color: $dark-tools-icon-color !important;
  }
}

.outline-none {
  outline: none !important;
}

// custom color picker end

//dataux canavas styles
.engine-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  #renderCanvas {
    width: 100%;
    height: 100%;
    touch-action: none;
  }

  #renderCanvas:focus {
    outline: none;
  }
}

.mat-expansion-indicator::after {
  top: -3px;
  position: relative;
}


///////////////////////////////////

.sidebarSection {
  .sidebar {
    .sidebar-list {
      width: 35px;
      height: calc(100vh - 0px);
      position: absolute;
      background-color: #1c202b;
      top: 60px;
      z-index: 9999;
      opacity: 0.9;
      color: #ffff;

      .topSidebarList {
        height: calc(100vh - 88px);
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 1px;
        }
      }

      .panel_handle_label {
        display: block;
        transform: rotate(-90deg);
        position: relative;
        left: -14px;
        top: 14px;
        transform-origin: bottom;
        width: 82px;
        padding: 8px 8px;
        text-align: center;
      }

      .panel_handle {
        height: 95px;
        transform: translateX(0);
        cursor: pointer;
      }

      .activeNav {
        border-left: 2px solid;
        border-color: #5a6dd6;
      }
    }

    .sidebar-icon {
      position: absolute;
      bottom: 0px;
      left: 5px;
      cursor: pointer;
      z-index: 9999;
    }
  }

  .sidebar-panel {
    width: 95%;
    height: calc(100vh - 60px);
    position: absolute;
    top: 60px;
    left: 35px;
    // border-radius: 0px 12px 12px 0px;
  }

  .showSecton {
    display: block;
  }

  .hideSection {
    display: none;
  }

  .reverse-theme {
    background-color: #fff !important;
    color: #000 !important;
  }
}


.darkMode .mat-menu-pamat-menu-panelnel,.mat-mdc-menu-panel {
  background: #424242;
}

.collectionCategoryViewerSection {
  .headSection {
    .leftSection {
      width: 90%;
      text-align: left;
    }

    .rightSection {
      width: 10%;
      text-align: right;
    }
  }

  .selectAllSection {
    border-color: rgba(184, 172, 199, 0.3);

    .leftSection {
      width: 94%;
      float: left;
    }

    .rightSection {
      width: 1rem;
      float: right;
      text-align: right;
    }

  }

  .collectionList {
    height: calc(100vh - 165px);
    overflow: auto;
    overflow-x: hidden;

    ::ng-deep cdk-virtual-scroll-viewport {
      height: calc(100vh - 160px);
    }

    ::ng-deep .cdk-virtual-scroll-content-wrapper {
      // position: relative !important;
    }

    .mat-list-base .mat-list-item,
    .mat-list-base .mat-list-option {
      height: 60px;
    }

    .mat-list-item {
      border-color: rgb(184 172 199 / 30%);
    }

    .listData {
      border-left: 2px solid grey;

      .leftSection {
        width: 60%;
        float: left;
      }

      .rightSection {
        width: 40%;
        float: right;
        text-align: right;

        .rightAction {
          position: relative;
          right: 2px;

          .statusColor {
            color: grey;
          }
        }
      }
    }

    .listData:hover {
      .child-hidden {
        visibility: visible !important;
      }

      .child-none {
        display: none !important;
      }

      .child-view {
        display: initial !important;
      }
    }
  }

  .container-100 {
    width: 100%;
  }

  .darkMode .mat-input-element {
    caret-color: #A1A3A8 !important;
  }
}


.filterPanel {
  height: calc(100vh - 60px);
  top: 0px;
  z-index: 9999;

  .headSection {
    .leftSection {
      width: 83%;
      float: left;
    }

    .rightSection {
      width: 17%;
      float: right;
      text-align: right;
    }
  }

  .contentSection {
    height: calc(100vh - 115px);
    overflow: auto;

    .listItem {
      .leftSection {
        width: 85%;

        span.colorLabel {
          width: 8px;
          height: 8px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 3px;
          position: relative;
          top: -2px;
        }
      }

      .rightSection {
        width: 15%;
        text-align: center;
      }
    }
  }

}

.mobileView {
  display: none;
}

@media only screen and (min-width:320px) and (max-width:760px) {
  .workSpace .mata-data img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  .mobileView {
    display: block;
}
  .sidebarSection .sidebar .sidebar-icon {
    position: absolute;
    bottom: 10px;
  }

  // .workSpace {
  //   padding-bottom: 2rem;
  //   position: relative;
  //   top: 5rem;
  // }

  .panel_handle_label {
    transform: unset !important;
    left: 0px !important;
    top: 8px !important;
    text-align: left !important;
  }

  .collectionCategoryViewerSection .headSection .leftSection {
    width: 70%;
    float: left;
  }

  .collectionCategoryViewerSection .headSection .rightSection {
    width: 10%;
    float: right;
    text-align: right;
  }

  .sidebarSection .sidebar-panel {
    top: 4rem;
    width: 100%;
    height: calc(100vh - 64px);
    position: absolute;
    left: unset;
  }

  // .sidebar {
  //   .sidebar-list {
  //     width: 140px !important;
  //   }

  //   .panel_handle {
  //     height: 50px !important;
  //     transform: translateX(0);
  //     cursor: pointer;
  //   }
  // }

  .container .grid-row {
    display: grid;
    grid-template-columns: unset !important;
    grid-gap: 15px;
  }

  .collectionCategoryViewerSection .selectAllSection .leftSection {
    width: 80%;
    float: left;
  }

  .collectionCategoryViewerSection .selectAllSection .rightSection {
    width: 10%;
    float: right;
    text-align: right;
  }

  .defectspopup-modal .defects-dialog {
    top: 9% !important;
    left: 4% !important;
    width: 78% !important;
  }

  .camera-dialog {
    top: 12% !important;
    left: 10% !important;
    width: 70% !important;
  }


  .navbar {
    display: flex;
    overflow: hidden;
    background-color: #333;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .navbar .navList {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    width: 50%;
  }

  .activeNav {
    border-bottom: 2px solid;
    border-color: #5a6dd6;
    // color: #5a6dd6 !important;
  }

  .sidebar {
    display: none;
  }

  .textarea {
    width: 94% !important;
    color:#fff;
    font-family: $primary_font;
    font-size: 12px;
  }

  .arrowDown {
    display: none !important;
  }

  .loaderPart .syncLoader {
    width: 50% !important;
  }

}