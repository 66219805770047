  /***********************************************
 * Author      : S.Vengadeshwaran                *
 * Created Date: 25/01/2022                      *
 * Title       : Font                            *
 * File Type   : SCSS                            *
 * Purpose     : Font family Style sheet         *
 *************************************************/
  @font-face {
    font-family: 'Inter-Regular';
    src: url('../fonts/Inter-Regular.ttf') format('opentype');
  }

  @font-face {
    font-family: 'Inter-Light';
    src: url('../fonts/Inter-Light.ttf') format('opentype');
  }

  @font-face {
    font-family: 'Inter-ExtraLight';
    src: url('../fonts/Inter-ExtraLight.ttf') format('opentype');
  }

  @font-face {
    font-family: 'Inter-Bold';
    src: url('../fonts/Inter-Bold.ttf') format('opentype');
  }

  @font-face {
    font-family: 'Inter-Thin';
    src: url('../fonts/Inter-Thin.ttf') format('opentype');
  }